import { Auth0Provider } from "@auth0/auth0-react";
// import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import * as serviceWorker from "./serviceWorker";
import { Navigate } from "react-router-dom";
import App from "./components/app/";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

// const store = configureStore({ reducer: mapSlice });
import store from "./redux/store";

const element = document.getElementById("root");
const root = createRoot(element!);

const onRedirectCallback = () => {
  return <Navigate to="/" />;
};

const providerConfig = {
  domain: "on2next.us.auth0.com",
  clientId: "vegYT7EIhUYmSqORTXlDtzm2VtlpMBRd",
  redirectUri: window.location.origin,
  onRedirectCallback,
};

//TODO: Add <Provider> for redux store.
root.render(
  //<React.StrictMode>
  <Auth0Provider {...providerConfig}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Auth0Provider>
  //</React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();